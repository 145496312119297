.important-notices
  display block
  max-width 800px
  margin 32px auto
  padding 24px 16px
  border 1px solid #f4292a
  background #f4292a
  font-size 18px
  font-weight 700
  text-align center
  span
    font-size: .8em
  +mq('sp')
    font-size 14px
    padding 24px 8px

.important-notices-link
  color #fff
  p
    text-decoration underline

.important-notices-image
  width 55px
  height 55px
  +mq('sp')
    width 40px
    height 40px

.important-notices-heading
  max-width 800px
  margin .5em auto
  //background #ffff00
  color #fff
  font-size 32px
  font-weight 700
  text-align center
  text-decoration none
  span
    position relative
  span::before
    content ''
    position absolute
    bottom -4px
    left 0
    display block
    width 100%
    height 3px
    background #fff
  +mq('sp')
    font-size 18px

.important-notices-content
  display flex
  text-align left
  +mq('sp')
    display block

.important-notices-date
  width 160px

.important-notices-topic
  flex 1
