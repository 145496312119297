.mobile-menu
  position fixed
  top 16px
  right 16px
  display none
  width 50px
  height 50px
  padding 4px 0
  background colors('nav')
  &.is-open
    .menu-bar
      &:nth-child(1)
        transform rotate(45deg) translateX(8px) translateY(7px) translateZ(0)
      &:nth-child(2)
        opacity 0
        transform translateX(40px)
      &:last-child
        transform rotate(-45deg) translateX(8px) translateY(-7px) translateZ(0)
  +mq('md')
    display block

.menu-bar
  width 70%
  height 3px
  margin 8px auto
  border-radius 2px
  background-color #fff
  transition .3s ease
