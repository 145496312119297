.page-news-container
  position relative
  display flex
  justify-content space-between
  max-width 960px
  width 100%
  height auto
  margin 0 auto
  padding-bottom 20px
  +mq('md')
    display block

.page-news-container-left
  position relative
  z-index 1
  flex 1 0 48%
  padding 20px 0
  +mq('md')
    width 98%
    margin 0 auto 40px
    border-width 2px

.page-news-container-upper
  width 95%
  max-width 960px
  margin 0 auto -1px
  height 40px
  background url('../images/page/bg-upper-2.png') bottom center no-repeat
  background-size 100%
  +mq('sp')
    height 50px

.page-news-container-middle
  position relatove
  max-width 960px
  margin auto
  padding 48px 6%
  position relative
  background colors('news-bg')
  color colors('text-color')
  +mq('md')
    width 95%
    padding 20px 3%

.page-news-container-lower
  width 95%
  max-width 960px
  margin -1px auto 0
  height 40px
  background url('../images/page/bg-lower-2.png') top center no-repeat
  background-size 100%
  +mq('sp')
    height 50px

.inner-scroll
  height 200px
  overflow-x hidden
  overflow-y scroll
  +mq('sp')
    height 240px

.page-news-container-right
  position relative
  z-index 1
  flex 1 0 48%
  margin-left 1%
  padding 20px 8px
  +mq('md')
    width 98%
    margin auto
    border-width 2px

.page-news-container-red
  background colors('topics-bg')
  border 1px solid colors('news-border-2')
  border-radius 16px

.page-news-container-blue
  background colors('twitter-bg')
  border 1px solid colors('twitter-border')

.page-news-container-heading
  display block
  width 100%
  margin 0 auto 20px

.page-news-container-inner
  position relative
  height 400px
  margin-bottom 0
  color colors('news-text')
  &.news-only
    height 200px
    padding-bottom 40px
  +mq('sp')
    padding 5%
    &.news-only
      height 320px
  dl
    margin 0 0 16px
    padding-top 16px
    border-bottom 1px dashed colors('news-border-2')
  dt
    width 100%
    height auto
    color colors('news-heading')
    font-weight bold
  dd
    width 100%
    height auto
    margin 0 0 16px
  +mq('md')
    dl
      margin 0 0 12px
    dt
      padding 0
    dd
      padding 0
      margin 0 0 12px

.page-news-container-bg-1
.page-news-container-bg-2
.page-news-container-bg-3
.page-news-container-bg-4
  position absolute
  display block
  width 15vw
  max-width 80px
  height 15vw
  max-height 80px

.page-news-container-bg-1
  top 8px
  left 8px

.page-news-container-bg-2
  top 8px
  right 8px

.page-news-container-bg-3
  bottom 8px
  left 8px

.page-news-container-bg-4
  bottom 8px
  right 8px
