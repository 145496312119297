.home-content
  padding 0
  //background url('../images/bg.jpg')
  //background-size 100%
  //background-repeat repeat
  //background-color $bg
  background-color: rgba(255, 255, 255, 1)
  background-image: repeating-linear-gradient(90deg, transparent, transparent 4%, rgba(255, 252, 161, 1) 4%, rgba(255, 252, 161, 1) 8% )
  background-repeat repeat
  background-size 100%
