@font-face {
  font-family: "MyFont";
  src: url("../fonts/boku.eot") format("eot"), url("../fonts/boku.woff") format("woff");
}
.hidden {
  display: none;
}
.h3-img-1 {
  width: 95%;
  height: auto;
  margin: 0 auto 24px;
}
.h3-img-2 {
  width: 80%;
  max-width: 800px;
  height: auto;
  margin: 0 auto 36px;
}
.h3-img-2 img {
  width: 100% !important;
}
.h3-img-3 {
  max-width: 360px;
  height: auto;
  margin: 0 auto 24px;
}
.attention {
  color: #f00;
}
.details {
  float: right;
}
.mb8 {
  margin-bottom: 8px !important;
}
.mb16 {
  margin-bottom: 16px !important;
}
.mb24 {
  margin-bottom: 24px !important;
}
.mb32 {
  margin-bottom: 32px !important;
}
.mb40 {
  margin-bottom: 40px !important;
}
.mb48 {
  margin-bottom: 48px !important;
}
.align-center {
  text-align: center;
}
.color-red {
  color: #f00;
}
html,
body {
  width: 100%;
}
body {
  font-size: 16px;
  font-family: "Hiragino Kaku Gothic ProN", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Hiragino Kaku Gothic ProN", "Yu Gothic", sans-serif;
  line-height: 1.6;
  position: relative;
  background-color: #fff;
  background-size: 174px;
  background-repeat: repeat;
  word-wrap: break-word;
  -webkit-text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
}
@media only screen and (max-width: 900px) {
  body {
    font-size: 14px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
a {
  text-decoration: none;
}
img {
  max-width: 100%;
}
li {
  list-style: none;
}
*,
::before,
::after {
  background-repeat: no-repeat;
  box-sizing: inherit;
}
::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit;
}
html {
  box-sizing: border-box;
  cursor: default;
  font-family: sans-serif;
  line-height: 1.5;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
article,
aside,
footer,
header,
nav,
section {
  display: block;
}
body {
  margin: 0;
}
h1 {
  font-size: 2em;
  margin: 0;
}
figcaption,
figure,
main {
  display: block;
}
figure {
  margin: 1em 40px;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
ol,
ul {
  margin: 0;
  padding: 0;
}
pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: inherit;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
dfn {
  font-style: italic;
}
mark {
  background-color: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
::-moz-selection {
  background-color: #b3d4fc;
  color: #000;
  text-shadow: none;
}
::selection {
  background-color: #b3d4fc;
  color: #000;
  text-shadow: none;
}
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}
audio,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
img {
  border-style: none;
}
svg {
  fill: currentColor;
}
svg:not(:root) {
  overflow: hidden;
}
table {
  border-collapse: collapse;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
}
button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
progress {
  display: inline-block;
  vertical-align: baseline;
}
textarea {
  overflow: auto;
  resize: vertical;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
details,
menu {
  display: block;
}
summary {
  display: list-item;
}
canvas {
  display: inline-block;
}
template {
  display: none;
}
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
[hidden] {
  display: none;
}
[aria-busy="true"] {
  cursor: progress;
}
[aria-controls] {
  cursor: pointer;
}
[aria-hidden="false"][hidden] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}
[aria-hidden="false"][hidden]:focus {
  clip: auto;
}
[aria-disabled] {
  cursor: default;
}
#footer {
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0;
}
.footer-top {
  width: 100%;
  height: 6.4px;
}
#footer-content {
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 80px 0 24px;
}
#footer-content .cafe-logo {
  width: 218px;
  height: auto;
  margin: 0 auto 8px;
  padding: 0;
}
#footer-content .copy {
  color: #000;
  font-size: 80%;
  font-weight: bold;
  text-align: center;
  margin-bottom: 8px;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 4px 3%;
  background: #a01e27;
}
@media only screen and (max-width: 900px) {
  .header {
    position: relative;
  }
}
.header-osaka {
  background: #fff;
}
.header-nagoya {
  background: #fff;
}
.header-content {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 960px;
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0;
}
.header-content .bx-left {
  width: 20%;
  height: auto;
  margin-right: 5%;
  padding: 0;
}
.header-content .bx-right {
  width: 65%;
  height: auto;
  margin: 0;
  padding: 0;
}
.header-content .bx-right ul {
  display: flex;
  border-right: 1px solid #fbf5e5;
}
.header-content .bx-right li {
  list-style: none;
  width: 33.3%;
  height: auto;
  margin: 0;
  padding: 0 8px;
  border-left: 1px solid #fbf5e5;
  box-sizing: border-box;
  transition: all 0.4s;
}
.header-content .bx-right a:hover {
  opacity: 0.64;
}
@media only screen and (max-width: 900px) {
  .header-content .bx-left {
    width: 200px;
    margin-right: 0;
  }
  .header-content .bx-left.osaka {
    width: 240px;
    margin-left: -20px;
  }
  .header-content .bx-right {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #a01e27;
    transform: scaleY(0);
    transform-origin: top;
    transition: 0.15s ease-out;
  }
  .header-content .bx-right.is-open {
    transform: scaleY(1);
  }
  .header-content .bx-right ul {
    display: block;
    border: none;
    border-bottom: 1px solid #fbf5e5;
  }
  .header-content .bx-right li {
    width: 100%;
    height: auto;
    margin: 0;
    border: none;
    border-top: 1px solid #fbf5e5;
    text-align: center;
  }
  .header-content .bx-right li.none-pc {
    border: none;
  }
  .header-content .bx-right li.none-pc a {
    padding: 4px 0;
  }
  .header-content .bx-right li.none-pc img {
    max-width: 200px;
  }
  .header-content .bx-right a,
  .header-content .bx-right .deactive {
    display: block;
    width: 100%;
    padding: 16px 8px;
  }
  .header-content .bx-right img {
    max-width: 160px;
  }
  .header-content .bx-right img.osaka-logo {
    max-width: 240px;
    margin-left: -20px;
  }
}
.deactive {
  opacity: 0.64;
}
.deactive:hover {
  opacity: 0.64;
}
.home-content {
  padding: 0;
  background-color: #fff;
  background-image: repeating-linear-gradient(90deg, transparent, transparent 4%, #fffca1 4%, #fffca1 8%);
  background-repeat: repeat;
  background-size: 100%;
}
.home-header {
  background: #a01e27;
  text-align: center;
}
.home-header-logo {
  width: 240px;
}
@media only screen and (max-width: 900px) {
  .home-header-logo {
    width: 160px;
  }
}
.mobile-menu {
  position: fixed;
  top: 16px;
  right: 16px;
  display: none;
  width: 50px;
  height: 50px;
  padding: 4px 0;
  background: #a01e27;
}
.mobile-menu.is-open .menu-bar:nth-child(1) {
  transform: rotate(45deg) translateX(8px) translateY(7px) translateZ(0);
}
.mobile-menu.is-open .menu-bar:nth-child(2) {
  opacity: 0;
  transform: translateX(40px);
}
.mobile-menu.is-open .menu-bar:last-child {
  transform: rotate(-45deg) translateX(8px) translateY(-7px) translateZ(0);
}
@media only screen and (max-width: 900px) {
  .mobile-menu {
    display: block;
  }
}
.menu-bar {
  width: 70%;
  height: 3px;
  margin: 8px auto;
  border-radius: 2px;
  background-color: #fff;
  transition: 0.3s ease;
}
.page-footer-upper {
  width: 100%;
  height: 6.4px;
}
.page-footer {
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0;
}
.page-footer-content {
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 40px 0;
}
.page-copy {
  color: #000;
  font-size: 80%;
  font-weight: bold;
  text-align: center;
  margin-bottom: 8px;
}
.page-footer-lower {
  height: 80px;
  background: #a01e27;
}
.page-footer-lower.page-footer-lower-osaka {
  background: #a67f00;
}
.page-footer-lower.page-footer-lower-nagoya {
  background: #a67f00;
}
.home-nav {
  margin-bottom: 80px;
  background: url("../images/bg-kv.jpg") top center;
  background-size: 91px 112px;
}
.home-nav-wrapper {
  display: flex;
  justify-content: center;
  max-width: 800px;
  margin: auto;
  padding: 8px;
}
.home-nav-item {
  display: block;
  margin: 24px 8px;
}
@media only screen and (max-width: 900px) {
  .home-nav-item {
    margin: 8px 4px;
  }
}
.slider .slick-track {
  padding: 0;
}
.slider-item {
  position: relative;
  overflow: hidden;
}
.slider-item:focus {
  outline: none;
}
.slider-item-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media only screen and (max-width: 900px) {
  .slider-item-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
}
.slider-item-image {
  width: 280px;
  margin: 24px 0 0 24px;
  padding-bottom: 48px;
}
@media only screen and (max-width: 900px) {
  .slider-item-image {
    width: 48vw;
    min-width: 280px;
    max-width: 400px;
    margin: 10vw auto 2vw;
    padding-bottom: 24px;
  }
}
@media only screen and (max-width: 640px) {
  .slider-item-image {
    width: 85%;
    min-width: 0;
    max-width: 240px;
    padding-bottom: 16px;
  }
}
.slider-inner {
  width: 540px;
  margin-left: 64px;
}
@media only screen and (max-width: 900px) {
  .slider-inner {
    width: 100%;
    margin: 0;
  }
  .slider-inner p {
    display: none;
  }
}
.slider-heading {
  color: #793537;
  font-size: 28px;
}
@media only screen and (max-width: 900px) {
  .slider-heading {
    margin-bottom: 10vw;
    font-size: 3.5vw;
    text-align: center;
  }
}
@media only screen and (max-width: 640px) {
  .slider-heading {
    font-size: 4vw;
  }
}
.slider-item-bg {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 160px;
}
@media only screen and (max-width: 900px) {
  .slider-item-bg {
    width: 40%;
  }
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 1;
}
.slick-prev {
  left: 4px;
  z-index: 1;
  width: 40px;
  height: 40px;
}
.slick-prev:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: block;
  width: 24px;
  height: 24px;
  border-top: 4px solid #793537;
  border-right: 4px solid #793537;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.slick-prev:hover {
  cursor: pointer;
}
@media only screen and (max-width: 900px) {
  .slick-prev:before {
    left: -20px;
    width: 16px;
    height: 16px;
  }
}
.slick-next {
  right: 4px;
  z-index: 1;
  width: 40px;
  height: 40px;
}
.slick-next:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: block;
  width: 24px;
  height: 24px;
  border-top: 4px solid #793537;
  border-right: 4px solid #793537;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.slick-next:hover {
  cursor: pointer;
}
@media only screen and (max-width: 900px) {
  .slick-next:before {
    right: -20px;
    width: 16px;
    height: 16px;
  }
}
@media only screen and (max-width: 900px) {
  .is-pc {
    display: none;
  }
}
@media only screen and (min-width: 900px) {
  .none-pc {
    display: none;
  }
}
.is-tb {
  display: none;
}
@media only screen and (max-width: 900px) {
  .is-tb {
    display: block;
  }
}
@media only screen and (max-width: 640px) {
  .is-tb {
    display: none;
  }
}
.is-sp {
  display: none;
}
@media only screen and (max-width: 640px) {
  .is-sp {
    display: block;
  }
}
.clearfix::after {
  content: "";
  display: block;
  clear: both;
}
.inner {
  width: 900px;
  margin: 0 auto;
}
@media only screen and (max-width: 900px) {
  .inner {
    width: auto;
    margin: 0 15px;
  }
}
#address-container {
  width: 95%;
  max-width: 960px;
  margin: auto;
  padding: 1em;
  background: #fff;
}
.section-address {
  position: relative;
  width: 100%;
  max-width: 960px;
  height: auto;
  margin: auto;
  padding: 16px;
  background: #fff;
}
.section-address iframe {
  width: 100%;
}
.section-address-inner {
  padding: 1em;
  color: #333;
}
.section-address-inner dt,
.section-address-inner dd,
.section-address-inner dd a {
  color: #333;
  font-size: 14px;
  font-weight: 400;
}
.section-address-inner dl {
  display: flex;
  margin: 0 auto 16px;
  border-bottom: 1px dashed #333;
}
.section-address-inner dt {
  width: 24%;
  height: auto;
}
.section-address-inner dd {
  width: 75%;
  height: auto;
  margin: 0 0 16px;
}
@media only screen and (max-width: 900px) {
  .section-address-inner {
    padding: 2em 1em;
  }
  .section-address-inner dl {
    display: block;
    margin: 0 auto 12px;
  }
  .section-address-inner dt {
    width: 100%;
    padding: 0 4%;
    box-sizing: border-box;
  }
  .section-address-inner dd {
    width: 100%;
    padding: 0 4%;
    box-sizing: border-box;
    margin: 0 0 12px;
  }
}
.important-notices {
  display: block;
  max-width: 800px;
  margin: 32px auto;
  padding: 24px 16px;
  border: 1px solid #f4292a;
  background: #f4292a;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}
.important-notices span {
  font-size: 0.8em;
}
@media only screen and (max-width: 640px) {
  .important-notices {
    font-size: 14px;
    padding: 24px 8px;
  }
}
.important-notices-link {
  color: #fff;
}
.important-notices-link p {
  text-decoration: underline;
}
.important-notices-image {
  width: 55px;
  height: 55px;
}
@media only screen and (max-width: 640px) {
  .important-notices-image {
    width: 40px;
    height: 40px;
  }
}
.important-notices-heading {
  max-width: 800px;
  margin: 0.5em auto;
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}
.important-notices-heading span {
  position: relative;
}
.important-notices-heading span::before {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  display: block;
  width: 100%;
  height: 3px;
  background: #fff;
}
@media only screen and (max-width: 640px) {
  .important-notices-heading {
    font-size: 18px;
  }
}
.important-notices-content {
  display: flex;
  text-align: left;
}
@media only screen and (max-width: 640px) {
  .important-notices-content {
    display: block;
  }
}
.important-notices-date {
  width: 160px;
}
.important-notices-topic {
  flex: 1;
}
.main-container {
  position: relative;
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0;
}
.section-information {
  position: relative;
  padding: 30px 6%;
  position: relative;
}
@media only screen and (max-width: 900px) {
  .section-information {
    padding: 20px 0;
  }
}
.information-wrapper {
  position: relative;
}
.information-wrapper-upper {
  width: 95%;
  max-width: 960px;
  margin: 0 auto -1px;
  height: 120px;
  background: url("../images/page/bg-inner-upper.png") bottom center;
  background-repeat: repeat-x;
  background-size: 100%;
}
@media only screen and (max-width: 900px) {
  .information-wrapper-upper {
    height: 90px;
  }
}
@media only screen and (max-width: 640px) {
  .information-wrapper-upper {
    height: 60px;
  }
}
.information-wrapper-middle {
  position: relative;
  width: 95%;
  max-width: 960px;
  margin: 0 auto 0;
  padding: 40px 6% 8px;
  position: relative;
  background: #fff;
  border: solid 2px #ffbf5c;
  color: #000;
}
@media only screen and (max-width: 900px) {
  .information-wrapper-middle {
    padding: 24px 3% 20px;
  }
}
.information-wrapper-lower {
  width: 95%;
  max-width: 960px;
  margin: -1px auto 0;
  height: 120px;
  background: url("../images/page/bg-inner-lower.png") top center;
  background-repeat: repeat-x;
  background-size: 100%;
}
@media only screen and (max-width: 900px) {
  .information-wrapper-lower {
    height: 90px;
  }
}
@media only screen and (max-width: 640px) {
  .information-wrapper-lower {
    height: 60px;
  }
}
.information-wrapper-heading {
  position: relative;
  width: 100%;
  margin: auto;
}
.information-wrapper-inner {
  max-width: 640px;
  margin: 0 auto 12px;
  padding: 12px 16px 0;
}
.information-wrapper-inner+.information-wrapper-inner {
  border-top: 1px solid #3c2300;
}
.information-wrapper-inner a {
  color: #000;
  font-weight: bold;
}
.information-wrapper-inner table {
  margin: auto;
}
.information-wrapper-inner th {
  text-align: left;
}
.information-wrapper-inner td {
  height: auto;
  margin: 0;
  padding: 2px 4px;
  box-sizing: border-box;
  color: #000;
}
@media only screen and (max-width: 900px) {
  .information-wrapper-inner {
    display: block;
  }
}
@media only screen and (max-width: 640px) {
  .information-wrapper-inner table {
    width: 100%;
  }
}
.headline {
  width: 320px;
  height: 100%;
  margin: 16px auto;
  text-align: center;
  background: #a01e27;
  border-radius: 40px;
  color: #fff;
  font-weight: bold;
}
@media only screen and (max-width: 640px) {
  .headline {
    width: 200px;
  }
}
.textline {
  flex: 1;
  margin: 16px 0;
  line-height: 1.6;
}
.textline.large {
  margin: 12px 0;
  font-size: 125%;
  font-weight: 700;
}
.time-line {
  display: flex;
}
@media only screen and (max-width: 640px) {
  .time-line {
    display: block;
  }
}
.time-line-item {
  flex: 1;
}
@media only screen and (max-width: 900px) {
  .time-line-item {
    max-width: 280px;
  }
}
@media only screen and (max-width: 640px) {
  .time-line-item {
    margin-bottom: 1em;
  }
}
.textline-ul {
  margin-left: 1em;
}
.textline-ul-item {
  list-style: disc;
}
.small {
  font-size: 0.8em;
}
.inline-block {
  display: inline-block;
}
.news-components {
  display: flex;
  max-width: 880px;
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding-bottom: 20px;
}
@media only screen and (max-width: 900px) {
  .news-components {
    display: block;
  }
}
.news-components-left {
  width: 49%;
  background: #efeae2;
  margin-right: 1%;
  padding: 20px 8px;
}
@media only screen and (max-width: 900px) {
  .news-components-left {
    width: 98%;
    margin: 0 auto 40px;
  }
}
.inner-scroll {
  height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
}
dl {
  margin: 0 0 16px;
}
dt {
  width: 100%;
  height: auto;
  color: #793537;
  font-weight: bold;
}
dd {
  width: 100%;
  height: auto;
  margin: 0 0 16px;
}
@media only screen and (max-width: 900px) {
  dl {
    margin: 0 0 12px;
  }
  dt {
    padding: 0;
  }
  dd {
    padding: 0;
    margin: 0 0 12px;
  }
}
.news-components-right {
  width: 100%;
  max-width: 960px;
  margin: auto;
  padding: 20px 8px;
  background: #fff;
}
@media only screen and (max-width: 900px) {
  .news-components-right {
    width: 95%;
    margin: auto;
  }
}
.news-components-heading {
  display: block;
  margin: 0 auto 20px;
}
.news-components-inner {
  max-width: 800px;
  height: 310px;
  margin: 0 auto;
}
@media only screen and (max-width: 900px) {
  .news-components-inner {
    max-width: 90%;
  }
}
.notices {
  margin-bottom: 40px;
  padding: 2em 2em;
  line-height: 1.8;
}
.notices span {
  display: inline-block;
  margin-left: 1em;
  font-size: 0.8em;
}
@media only screen and (max-width: 640px) {
  .notices {
    padding: 2em 1em;
  }
}
.notices-heading {
  text-align: center;
  color: #f00;
  font-size: 1.3em;
  font-weight: 700;
}
.notices-subheading {
  text-align: center;
  font-size: 1.3em;
}
.notices-section-heading {
  margin-top: 40px;
  font-weight: 700;
  font-size: 1.2em;
  text-align: center;
}
.back-btn {
  display: block;
  margin-bottom: 1em;
  color: #000;
}
.notices-ul {
  margin-left: 1em;
}
.notices-ul-item {
  list-style: disc;
}
.page-campaign {
  width: 95%;
  max-width: 960px;
  margin: auto;
  padding-top: 40px;
}
.goods-text {
  display: block;
  width: 90%;
  max-width: 640px;
  margin: auto;
}
.menu-button {
  display: flex;
  justify-content: center;
  width: 60%;
  max-width: 240px;
  margin: auto;
}
.menu-button-item {
  flex: 1;
}
.menu-button-item+.menu-button-item {
  margin-left: 5%;
}
.menu-text {
  display: block;
  width: 80%;
  max-width: 400px;
  margin: auto;
}
.page-news-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  max-width: 960px;
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding-bottom: 20px;
}
@media only screen and (max-width: 900px) {
  .page-news-container {
    display: block;
  }
}
.page-news-container-left {
  position: relative;
  z-index: 1;
  flex: 1 0 48%;
  padding: 20px 0;
}
@media only screen and (max-width: 900px) {
  .page-news-container-left {
    width: 98%;
    margin: 0 auto 40px;
    border-width: 2px;
  }
}
.page-news-container-upper {
  width: 95%;
  max-width: 960px;
  margin: 0 auto -1px;
  height: 40px;
  background: url("../images/page/bg-upper-2.png") bottom center no-repeat;
  background-size: 100%;
}
@media only screen and (max-width: 640px) {
  .page-news-container-upper {
    height: 50px;
  }
}
.page-news-container-middle {
  position: relatove;
  max-width: 960px;
  margin: auto;
  padding: 48px 6%;
  position: relative;
  background: #fff;
  color: #000;
}
@media only screen and (max-width: 900px) {
  .page-news-container-middle {
    width: 95%;
    padding: 20px 3%;
  }
}
.page-news-container-lower {
  width: 95%;
  max-width: 960px;
  margin: -1px auto 0;
  height: 40px;
  background: url("../images/page/bg-lower-2.png") top center no-repeat;
  background-size: 100%;
}
@media only screen and (max-width: 640px) {
  .page-news-container-lower {
    height: 50px;
  }
}
.inner-scroll {
  height: 200px;
  overflow-x: hidden;
  overflow-y: scroll;
}
@media only screen and (max-width: 640px) {
  .inner-scroll {
    height: 240px;
  }
}
.page-news-container-right {
  position: relative;
  z-index: 1;
  flex: 1 0 48%;
  margin-left: 1%;
  padding: 20px 8px;
}
@media only screen and (max-width: 900px) {
  .page-news-container-right {
    width: 98%;
    margin: auto;
    border-width: 2px;
  }
}
.page-news-container-red {
  background: #a01e27;
  border: 1px solid #fff;
  border-radius: 16px;
}
.page-news-container-blue {
  background: #fff;
  border: 1px solid #ffbf5c;
}
.page-news-container-heading {
  display: block;
  width: 100%;
  margin: 0 auto 20px;
}
.page-news-container-inner {
  position: relative;
  height: 400px;
  margin-bottom: 0;
  color: #fff;
}
.page-news-container-inner.news-only {
  height: 200px;
  padding-bottom: 40px;
}
@media only screen and (max-width: 640px) {
  .page-news-container-inner {
    padding: 5%;
  }
  .page-news-container-inner.news-only {
    height: 320px;
  }
}
.page-news-container-inner dl {
  margin: 0 0 16px;
  padding-top: 16px;
  border-bottom: 1px dashed #fff;
}
.page-news-container-inner dt {
  width: 100%;
  height: auto;
  color: #fff;
  font-weight: bold;
}
.page-news-container-inner dd {
  width: 100%;
  height: auto;
  margin: 0 0 16px;
}
@media only screen and (max-width: 900px) {
  .page-news-container-inner dl {
    margin: 0 0 12px;
  }
  .page-news-container-inner dt {
    padding: 0;
  }
  .page-news-container-inner dd {
    padding: 0;
    margin: 0 0 12px;
  }
}
.page-news-container-bg-1,
.page-news-container-bg-2,
.page-news-container-bg-3,
.page-news-container-bg-4 {
  position: absolute;
  display: block;
  width: 15vw;
  max-width: 80px;
  height: 15vw;
  max-height: 80px;
}
.page-news-container-bg-1 {
  top: 8px;
  left: 8px;
}
.page-news-container-bg-2 {
  top: 8px;
  right: 8px;
}
.page-news-container-bg-3 {
  bottom: 8px;
  left: 8px;
}
.page-news-container-bg-4 {
  bottom: 8px;
  right: 8px;
}
.section-reserve {
  max-width: 960px;
  width: 100%;
  height: auto;
  background: none;
}
.section-reserve {
  max-width: 1200px;
  width: 100%;
  height: auto;
  margin: 32px auto 80px;
  padding: 32px 0;
  text-align: center;
}
.section-reserve ul {
  display: flex;
  width: 95%;
  max-width: 700px;
  margin: auto;
}
@media only screen and (max-width: 900px) {
  .section-reserve ul {
    display: block;
  }
}
.section-reserve p {
  width: 95%;
  max-width: 660px;
  margin: auto;
  color: #3e3a39;
  font-size: 98%;
  text-align: left;
}
.section-reserve b {
  display: block;
  margin-top: 32px;
  font-size: 130%;
}
.section-reserve a {
  color: #3e3a39;
}
.reserve-heading img {
  width: 60%;
  max-width: 320px;
  height: auto;
}
.reserve-inner {
  position: relative;
  background: #fff;
}
@media only screen and (max-width: 640px) {
  .reserve-inner {
    padding: 40px 0 0;
  }
}
.reserve-wrapper {
  position: relative;
}
.reserve-bg {
  position: absolute;
  top: 8px;
  left: 2%;
  width: 100%;
  height: 100%;
  background: #462a19;
  border-radius: 0 40px 0 40px;
}
.reserve-middle {
  position: relative;
  margin-bottom: 40px;
  padding: 40px 0;
  background: #fff;
  border-radius: 0 40px 0 40px;
}
.reserve-text {
  display: block;
  width: 85%;
  max-width: 720px;
  margin: 24px auto 40px;
}
@media only screen and (max-width: 640px) {
  .reserve-text {
    margin: 24px auto 24px;
  }
}
.reserve-text-2 {
  display: block;
  width: 85%;
  max-width: 680px;
  margin: 0 auto 40px;
}
@media only screen and (max-width: 640px) {
  .reserve-text-2 {
    margin: 0 auto 24px;
  }
}
.reserve-text-3 {
  display: block;
  width: 95%;
  max-width: 670px;
  margin: 0 auto 40px;
}
.reserve-items {
  display: flex;
  width: 95%;
  margin: auto;
}
@media only screen and (max-width: 640px) {
  .reserve-items {
    display: block;
  }
}
.reserve-item {
  width: 46%;
  margin: 0 2% 24px;
}
@media only screen and (max-width: 900px) {
  .reserve-item {
    width: auto;
    margin: 0 auto 24px;
  }
}
.reserve-item.deactive::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  pointer-events: none;
}
.reserve-item.deactive a:hover {
  opacity: 1;
}
.reserve-item a:hover {
  opacity: 0.8;
}
@media only screen and (max-width: 900px) {
  .reserve-item {
    flex: 1;
    margin: 0 2% 24px;
  }
}
.reserve-text-4 {
  display: block;
  width: 95%;
  max-width: 420px;
  margin: 0 auto 60px;
}
@media only screen and (max-width: 640px) {
  .reserve-text-4 {
    margin: 0 auto 40px;
  }
}
.reserve-box-inner {
  position: relative;
  width: 95%;
  max-width: 890px;
  margin: 0 auto 24px;
}
.reserve-box {
  position: relative;
  display: block;
  width: 90%;
  margin: auto;
}
.reserve-contact {
  width: 95%;
  margin: 0 auto 40px;
  color: #3e3a39;
}
.reserve-contact a {
  color: #3e3a39;
}
.page-content {
  padding: 0 0 20px;
  background: url("../images/bg.jpg");
  background-size: 6%;
  background-repeat: repeat;
}
@media only screen and (max-width: 900px) {
  .page-content {
    margin-top: 0;
  }
}
.page-inner {
  position: relative;
  width: 100%;
  margin: auto;
  background-size: auto auto;
  background-repeat: repeat;
  background-size: 100%;
}
.page-inner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("../images/page/bg.png");
  background-size: 8%;
  background-repeat: repeat;
}
.page-inner-bg {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("../images/page/bg-cloud.png");
  background-size: 100%;
  background-repeat: repeat;
}
.page-attention {
  text-align: center;
}
.page-section {
  position: relative;
  width: 95%;
  max-width: 800px;
  margin: auto;
  padding-top: 40px;
}
.page-upper {
  padding-top: 80px;
  background-image: url("../images/bg-upper.jpg");
  background-size: 6%;
  background-repeat: repeat;
}
@media only screen and (max-width: 900px) {
  .page-upper {
    padding-top: 40px;
  }
}
.kv-upper {
  display: block;
  width: 100%;
  margin: auto;
  padding: 16px 0;
}
.kv {
  display: block;
  width: 100%;
  margin: auto;
}
.page-nav {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  max-width: 960px;
  margin: auto;
  padding: 40px 0;
}
@media only screen and (max-width: 900px) {
  .page-nav {
    gap: 8px;
    padding: 24px 16px;
  }
}
section {
  max-width: 960px;
  margin: auto;
  padding: 0 8px;
}
.section-heading {
  display: block;
  width: 60%;
  max-width: 480px;
  margin: 80px auto 40px;
}
.section-table {
  width: 100%;
  margin: 0 auto 40px;
}
.section-table tr {
  border-bottom: 1px dashed #a01e27;
  vertical-align: top;
  text-align: left;
}
.section-table th {
  min-width: 100px;
  padding: 8px 16px;
}
.section-table td {
  padding: 8px 16px;
}
@media only screen and (max-width: 900px) {
  .section-table th {
    min-width: 80px;
    padding: 8px 8px 8px 12px;
  }
}
iframe {
  width: 100% !important;
}
.menu-notes,
.present-notes {
  font-size: 0.8em;
  text-align: center;
}
.visit {
  display: block;
  width: 80%;
  max-width: 480px;
  margin: auto;
}
.notes-heading {
  margin: 80px 0 40px;
  padding-bottom: 24px;
  border-bottom: 2px dashed #a01e27;
}
.notes-heading-img {
  display: block;
  width: 40%;
  max-width: 160px;
  margin: auto;
}
.notes-inner {
  padding: 0 16px 24px;
  border-bottom: 2px dashed #a01e27;
}
.contact-inner {
  text-align: center;
}
.social {
  max-width: 880px;
  width: 98%;
  height: auto;
  margin: 32px auto 0;
  padding: 0;
}
.sns-buttons {
  display: flex;
  justify-content: center;
  padding: 0;
  overflow: hidden;
}
.sns-button {
  float: left;
  width: calc(100% / 5);
  margin: 0 2%;
  list-style: none;
}
@media only screen and (max-width: 640px) {
  .sns-button {
    width: calc(100% / 4);
    margin: 0;
  }
}
.sns-button a {
  display: block;
  margin: 0 4% 0 0;
  padding: 8% 0;
  box-shadow: 0 -2px 0 0 rgba(0,0,0,0.2) inset;
  text-align: center;
  text-decoration: none;
  color: #fff;
  transition: 0.2s;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
}
.sns-button:nth-child(4n) a {
  margin: 0;
}
.sns-button a:hover {
  opacity: 0.7;
}
.sns-button a:active {
  box-shadow: 0 2px 0 0 rgba(0,0,0,0.2) inset;
}
.sns-button-twitter a {
  background-color: #1b95e0;
}
.sns-button-facebook a {
  background-color: #3b5998;
}
.sns-button-hatena a {
  background-color: #00a4de;
}
.sns-button-google a {
  background-color: #db4437;
}
.sns-button-pocket a {
  background-color: #ee4056;
}
.sns-button-line a {
  background-color: #00b900;
}
.sns-button-slack a {
  background-color: #2ab27b;
}
.sns-button-mixi a {
  background-color: #e0c074;
}
@media only screen and (max-width: 640px) {
  #social {
    margin: 0 auto 24px;
  }
}
