.header
  position fixed
  top 0
  left 0
  z-index 100
  width 100%
  height auto
  margin 0 auto
  padding 4px 3%
  background colors('nav')
  +mq('md')
    position relative

.header-osaka
  background colors('nav-osaka')

.header-nagoya
  background colors('nav-nagoya')

.header-content
  display flex
  justify-content center
  align-items center
  max-width 960px
  width 100%
  height auto
  margin 0 auto
  padding 0

  .bx-left
    width 20%
    height auto
    margin-right 5%
    padding 0

  .bx-right
    width 65%
    height auto
    margin 0
    padding 0

    ul
      display flex
      border-right 1px solid colors('nav-border')

    li
      list-style none
      width 33.3%
      height auto
      margin 0
      padding 0 8px
      border-left 1px solid colors('nav-border')
      box-sizing border-box
      transition all .4s
    a
      &:hover
        opacity .64
  +mq('md')
    .bx-left
      width 200px
      margin-right 0
      &.osaka
        width 240px
        margin-left -20px
    .bx-right
      position fixed
      top 0
      left 0
      width 100%
      background colors('nav')
      transform scaleY(0)
      transform-origin top
      transition 0.15s ease-out
      &.is-open
        transform scaleY(1)
      ul
        display block
        border none
        border-bottom 1px solid colors('nav-border')
      li
        width 100%
        height auto
        margin 0
        border none
        border-top 1px solid colors('nav-border')
        text-align center
      li.none-pc
        border none
        a
          padding 4px 0
        img
          max-width 200px
      a,
      .deactive
        display block
        width 100%
        padding 16px 8px
      img
        max-width 160px
        &.osaka-logo
          max-width 240px
          margin-left -20px

.deactive
  opacity .64
  &:hover
    opacity .64
