.slider
  .slick-track
    padding 0

.slider-item
  position relative
  overflow hidden
  &:focus
    outline none

.slider-item-wrapper
  display flex
  justify-content flex-start
  align-items center
  +mq('md')
    flex-direction column
    align-items flex-start

.slider-item-image
  width 280px
  margin 24px 0 0 24px
  padding-bottom 48px
  +mq('md')
    width 48vw
    min-width 280px
    max-width 400px
    margin 10vw auto 2vw
    padding-bottom 24px
  +mq('sp')
    width 85%
    min-width 0
    max-width 240px
    padding-bottom 16px

.slider-inner
  width 540px
  margin-left 64px
  +mq('md')
    width 100%
    margin 0
    p
      display none

.slider-heading
  color colors('primary')
  font-size 28px
  +mq('md')
    margin-bottom 10vw
    font-size 3.5vw
    text-align center
  +mq('sp')
    font-size 4vw

.slider-item-bg
  position absolute
  right 0
  bottom 0
  width 160px
  +mq('md')
    width 40%

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
  opacity 1

.slick-prev
  left 4px
  z-index 1
  width 40px
  height 40px
  &:before
    content ''
    position absolute
    top 0
    right 0
    bottom 0
    left 0
    margin auto
    display block
    width 24px
    height 24px
    border-top 4px solid colors('primary')
    border-right 4px solid colors('primary')
    -webkit-transform rotate(-135deg)
    transform rotate(-135deg)
  &:hover
    cursor pointer
  +mq('md')
    &:before
      left -20px
      width 16px
      height 16px

.slick-next
  right 4px
  z-index 1
  width 40px
  height 40px
  &:before
    content ''
    position absolute
    top 0
    right 0
    bottom 0
    left 0
    margin auto
    display block
    width 24px
    height 24px
    border-top 4px solid colors('primary')
    border-right 4px solid colors('primary')
    -webkit-transform rotate(45deg)
    transform rotate(45deg)
  &:hover
    cursor pointer
  +mq('md')
    &:before
      right -20px
      width 16px
      height 16px
