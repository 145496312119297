.section-reserve
  max-width 960px
  width 100%
  height auto
  background none

.section-reserve
  max-width 1200px
  width 100%
  height auto
  margin 32px auto 80px
  padding 32px 0
  text-align center
  ul
    display flex
    width 95%
    max-width 700px
    margin auto
    +mq('md')
      display block
  p
    width 95%
    max-width 660px
    margin auto
    color #3E3A39
    font-size 98%
    text-align left
  b
    display block
    margin-top 32px
    font-size 130%
  a
    color #3E3A39

.reserve-heading img
  width 60%
  max-width 320px
  height auto

.reserve-inner
  position relative
  //padding 40px 0 0
  background #fff
  //border 2px solid #775A24
  +mq('sp')
    padding 40px 0 0

.reserve-wrapper
  position relative

.reserve-bg
  position absolute
  top 8px
  left 2%
  width 100%
  height 100%
  background colors('inner-bg-color')
  border-radius 0 40px 0 40px

.reserve-middle
  position relative
  margin-bottom 40px
  padding 40px 0
  background colors('inner-color')
  border-radius 0 40px 0 40px

.reserve-text
  display block
  width 85%
  max-width 720px
  margin 24px auto 40px
  +mq('sp')
    margin 24px auto 24px

.reserve-text-2
  display block
  width 85%
  max-width 680px
  margin 0 auto 40px
  +mq('sp')
    margin 0 auto 24px

.reserve-text-3
  display block
  width 95%
  max-width 670px
  margin 0 auto 40px

.reserve-items
  display flex
  width 95%
  margin auto
  +mq('sp')
    display block

.reserve-item
  width 46%
  margin 0 2% 24px
  +mq('md')
    width auto
    margin 0 auto 24px
  &.deactive::after
    content ""
    position absolute
    top 0
    left 0
    display block
    width 100%
    height 100%
    background rgba(0,0,0,.5)
    pointer-events none
  &.deactive a:hover
    opacity 1

  a:hover
    opacity .8
  +mq('md')
    flex 1
    margin 0 2% 24px

.reserve-text-4
  display block
  width 95%
  max-width 420px
  margin 0 auto 60px
  +mq('sp')
    margin 0 auto 40px

.reserve-box-inner
  position relative
  width 95%
  max-width 890px
  margin 0 auto 24px

.reserve-box
  position relative
  display block
  width 90%
  margin auto

.reserve-contact
  width 95%
  margin 0 auto 40px
  color #3E3A39
  a
    color #3E3A39
