.hidden
  display: none

.h3-img-1
  width: 95%
  height: auto
  margin: 0 auto 24px

.h3-img-2
  width: 80%
  max-width: 800px
  height: auto
  margin: 0 auto 36px

.h3-img-2 img
  width: 100%!important

.h3-img-3
  max-width: 360px
  height: auto
  margin: 0 auto 24px

.attention
  color: red

.details
  float: right

.mb8
  margin-bottom: 8px!important

.mb16
  margin-bottom: 16px!important

.mb24
  margin-bottom: 24px!important

.mb32
  margin-bottom: 32px!important

.mb40
  margin-bottom: 40px!important

.mb48
  margin-bottom: 48px!important

.align-center
  text-align center

+mq('md')
  .h3-img-1
    height auto
    margin -24 auto 8px
