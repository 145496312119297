.social
  max-width 880px
  width 98%
  height auto
  margin 32px auto 0
  padding 0

.sns-buttons
  display flex
  justify-content center
  padding 0
  overflow hidden

.sns-button
  float left
  width calc(100%/5)
  margin 0 2%
  list-style none
  +mq('sp')
    width calc(100%/4)
    margin 0

.sns-button a
  display block
  margin 0 4% 0 0
  padding 8% 0
  box-shadow 0 -2px 0 0 rgba(0,0,0,0.2)inset
  text-align center
  text-decoration none
  color #fff
  transition 0.2s
  font-size 12px
  font-weight bold
  white-space nowrap

.sns-button:nth-child(4n) a
  margin 0

.sns-button a:hover
  opacity 0.7

.sns-button a:active
  box-shadow 0 2px 0 0 rgba(0,0,0,0.2)inset

.sns-button-twitter a
  background-color #1B95E0

.sns-button-facebook a
  background-color #3B5998

.sns-button-hatena a
  background-color #00A4DE

.sns-button-google a
  background-color #DB4437

.sns-button-pocket a
  background-color #EE4056

.sns-button-line a
  background-color #00B900

.sns-button-slack a
  background-color #2AB27B

.sns-button-mixi a
  background-color #E0C074

+mq('sp')
  #social
    margin 0 auto 24px
