.home-nav
  margin-bottom 80px
  background url(../images/bg-kv.jpg) top center
  background-size 91px 112px

.home-nav-wrapper
  display flex
  justify-content center
  max-width 800px
  margin auto
  padding 8px

.home-nav-item
  display block
  margin 24px 8px
  +mq('md')
    margin 8px 4px
