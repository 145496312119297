$inner-width = 900px
$tb-width = 900px
$sp-width = 640px

// media query
mq(breakPointName)
  for name in breakPointName
    if name == 'sp'
      @media only screen and (max-width: $sp-width)
        {block}
    if name == 'md'
      @media only screen and (max-width: $tb-width)
        {block}
    if name == 'lg'
      @media only screen and (min-width: $inner-width)
        {block}
