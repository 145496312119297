.main-container
  position relative
  width 100%
  height auto
  margin 0 auto
  padding 0

.section-information
  position relative
  padding 30px 6%
  position relative
  +mq('md')
    padding 20px 0

.information-wrapper
 position relative

.information-wrapper-upper
  width 95%
  max-width 960px
  margin 0 auto -1px
  height 120px
  background url('../images/page/bg-inner-upper.png') bottom center
  background-repeat repeat-x
  background-size 100%
  +mq('md')
    height 90px
  +mq('sp')
    height 60px

.information-wrapper-middle
  position relative
  width 95%
  max-width 960px
  margin 0 auto 0
  padding 40px 6% 8px
  position relative
  //background url('../images/page/bg-inner-middle.png') top center
  //background-size 100%
  background colors('inner-color')
  border solid 2px colors('inner-border')
  color colors('text-color')
  +mq('md')
    padding 24px 3% 20px
    //border solid 2px colors('inner-border')

.information-wrapper-lower
  width 95%
  max-width 960px
  margin -1px auto 0
  height 120px
  background url('../images/page/bg-inner-lower.png') top center
  background-repeat repeat-x
  background-size 100%
  +mq('md')
    height 90px
  +mq('sp')
    height 60px

.information-wrapper-heading
  position relative
  width 100%
  margin auto

.information-wrapper-inner
  max-width 640px
  margin 0 auto 12px
  padding 12px 16px 0
  &+&
    border-top 1px solid colors('text-inner-border')
  a
    color colors('text-color')
    font-weight bold
  table
    margin auto
  th
    text-align left
  td
    height auto
    margin 0
    padding 2px 4px
    box-sizing border-box
    color colors('text-color')
  +mq('md')
    display block
  +mq('sp')
    table
      width 100%

.headline
  width 320px
  height 100%
  margin 16px auto
  text-align center
  background colors('headline-bg')
  border-radius 40px
  color colors('headline-text')
  font-weight bold
  +mq('sp')
    width 200px

.textline
  flex 1
  margin 16px 0
  line-height 1.6
  &.large
    margin 12px 0
    font-size 125%
    font-weight 700

.time-line
  display flex
  +mq('sp')
    display block

.time-line-item
  flex 1
  +mq('md')
    max-width 280px
  +mq('sp')
    margin-bottom 1em

.textline-ul
  margin-left 1em

.textline-ul-item
  list-style disc

.small
  font-size 0.8em

.inline-block
  display inline-block
