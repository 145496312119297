html
body
  width 100%


body
  font-size 16px
  font-family $sans-serif
  line-height 1.6
  position relative
  background-color colors('bg')
  background-size 174px
  background-repeat repeat
  word-wrap break-word
  -webkit-text-size-adjust 100%
  -webkit-overflow-scrolling touch
  -webkit-font-smoothing antialiased
  +mq('md')
    font-size 14px

h1
h2
h3
h4
h5
h6
  font-weight normal

a
  text-decoration none

img
  max-width 100%

li
  list-style none
