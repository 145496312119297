.page-footer-upper
  width 100%
  height 6.4px

.page-footer
  width 100%
  height auto
  margin 0 auto
  padding 0

.page-footer-content
  width 100%
  height auto
  margin 0 auto
  padding 40px 0

.page-copy
  color colors('copy')
  font-size 80%
  font-weight bold
  text-align center
  //text-shadow 0px 0px 1px #DFCBA9, 1px 1px 1px #DFCBA9, -1px 1px 1px #DFCBA9, 1px -1px 1px #DFCBA9, -1px -1px 1px #DFCBA9, 1px 0px 1px #DFCBA9, 0px 1px 1px #DFCBA9, -1px 0px 1px #DFCBA9, 0px -1px 1px #DFCBA9, 2px 2px 1px #DFCBA9, -2px 2px 1px #DFCBA9, 2px -2px 1px #DFCBA9, -2px -2px 1px #DFCBA9, 2px 0px 1px #DFCBA9, 0px 2px 1px #DFCBA9, -2px 0px 1px #DFCBA9, 0px -2px 1px #DFCBA9;
  margin-bottom 8px

.page-footer-lower
  height 80px
  background colors('page-footer')

  &.page-footer-lower-osaka
    background colors('page-footer-osaka')

  &.page-footer-lower-nagoya
    background colors('page-footer-nagoya')
