.page-content
  padding 0 0 20px
  background url('../images/bg.jpg')
  background-size 6%
  background-repeat repeat
  //background-color $bg
  +mq('md')
    margin-top 0

.page-inner
  position relative
  width 100%
  margin auto
  background-size: auto auto
  //background-color: rgba(255, 255, 255, 1)
  //background-image: repeating-linear-gradient(90deg, transparent, transparent 4%, rgba(255, 252, 161, 1) 4%, rgba(255, 252, 161, 1) 8% )
  //background colors('page-color')
  //background url('../images/bg-inner.jpg')
  //background url('../images/page/bg.jpg')
  background-repeat repeat
  background-size 100%
  &::before
    content: ''
    position absolute
    top 0
    left 0
    display block
    width 100%
    height 100%
    background-image url('../images/page/bg.png')
    background-size: 8%
    background-repeat repeat

.page-inner-bg
  position absolute
  top 0
  left 0
  display block
  width 100%
  height 100%
  background-image url('../images/page/bg-cloud.png')
  background-size: 100%
  background-repeat repeat


.page-attention
  text-align center

.page-section
  position relative
  width 95%
  max-width 800px
  margin auto
  padding-top 40px

.page-upper
  padding-top 80px
  background-image url('../images/bg-upper.jpg')
  background-size 6%
  background-repeat repeat
  +mq('md')
    padding-top 40px

.kv-upper
  display block
  width 100%
  //max-width 960px
  margin auto
  padding 16px 0

.kv
  display block
  width 100%
  //max-width 960px
  margin auto

.page-nav
  display grid
  grid-template-columns repeat(4, 1fr)
  gap 16px
  max-width 960px
  margin auto
  padding 40px 0
  +mq('md')
    gap 8px
    padding 24px 16px

section
  max-width 960px
  margin auto
  padding 0 8px

.section-heading
  display block
  width 60%
  max-width 480px
  margin 80px auto 40px

.section-table
  width 100%
  margin 0 auto 40px
  tr
    border-bottom 1px dashed #a01e27
    vertical-align top
    text-align left
  th
    min-width 100px
    padding 8px 16px
  td
    padding 8px 16px
  +mq('md')
    th
      min-width 80px
      padding 8px 8px 8px 12px

iframe
  width 100% !important

.menu-notes,
.present-notes
  font-size 0.8em
  text-align center

.visit
  display block
  width 80%
  max-width 480px
  margin auto

.notes-heading
  margin 80px 0 40px
  padding-bottom 24px
  border-bottom 2px dashed #a01e27

.notes-heading-img
  display block
  width 40%
  max-width 160px
  margin auto

.notes-inner
  padding 0 16px 24px
  border-bottom 2px dashed #a01e27

.contact-inner
  text-align center
