#address-container
  width 95%
  max-width 960px
  margin auto
  padding 1em
  background colors('address')

.section-address
  position relative
  width 100%
  max-width 960px
  height auto
  margin auto
  padding 16px
  background #fff
  iframe
    width 100%

.section-address-inner
  padding 1em
  color colors('address-text')
  dt,
  dd,
  dd a
    color colors('address-text')
    font-size 14px
    font-weight 400
  dl
    display flex
    margin 0 auto 16px
    border-bottom 1px dashed colors('address-border')
  dt
    width 24%
    height auto
  dd
    width 75%
    height auto
    margin 0 0 16px

  +mq('md')
    padding 2em 1em
    dl
      display block
      margin 0 auto 12px
    dt
      width 100%
      padding 0 4%
      box-sizing border-box
    dd
      width 100%
      padding 0 4%
      box-sizing border-box
      margin 0 0 12px
