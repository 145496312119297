.notices
  margin-bottom 40px
  padding 2em 2em
  line-height 1.8
  span
    display inline-block
    margin-left 1em
    font-size .8em
  +mq('sp')
    padding 2em 1em

.notices-heading
  text-align center
  color red
  font-size 1.3em
  font-weight 700

.notices-subheading
  text-align center
  font-size 1.3em

.notices-section-heading
  margin-top 40px
  font-weight 700
  font-size 1.2em
  text-align center

.back-btn
  display block
  margin-bottom 1em
  color #000

.notices-ul
  margin-left 1em

.notices-ul-item
  list-style disc
