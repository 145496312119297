.news-components
  display flex
  max-width 880px
  width 100%
  height auto
  margin 0 auto
  padding-bottom 20px
  +mq('md')
    display block

.news-components-left
  width 49%
  background colors('news-components')
  margin-right 1%
  padding 20px 8px
  +mq('md')
    width 98%
    margin 0 auto 40px

.inner-scroll
    height 300px
    overflow-x hidden
    overflow-y scroll
  dl
    margin 0 0 16px
  dt
    width 100%
    height auto
    color colors('primary')
    font-weight bold
  dd
    width 100%
    height auto
    margin 0 0 16px
  +mq('md')
    dl
      margin 0 0 12px
    dt
      padding 0
    dd
      padding 0
      margin 0 0 12px

.news-components-right
  width 100%
  max-width 960px
  margin auto
  padding 20px 8px
  background #fff
  +mq('md')
    width 95%
    margin auto

.news-components-heading
  display block
  margin 0 auto 20px

.news-components-inner
  max-width 800px
  height 310px
  margin 0 auto
  +mq('md')
    max-width 90%
