.menu-button
  display flex
  justify-content center
  width 60%
  max-width 240px
  margin auto

.menu-button-item
  flex 1
  &+&
    margin-left 5%

.menu-text
  display block
  width 80%
  max-width 400px
  margin auto
