#footer
  width 100%
  height auto
  margin 0 auto
  padding 0

.footer-top
  width 100%
  height 6.4px

#footer-content
  width 100%
  height auto
  margin 0 auto
  padding 80px 0 24px

#footer-content .cafe-logo
  width 218px
  height auto
  margin 0 auto 8px
  padding 0

#footer-content .copy
  color colors('copy')
  font-size 80%
  font-weight bold
  text-align center
  margin-bottom 8px
